import React from 'react'

import Dialog from './dialog/Dialog'
import OrderConfirmation from './order-confirmation/OrderConfirmation'
import CreateUser from './create-user/CreateUser'

import './modal.sass'

class Modal extends React.Component {
  componentDidMount() {
    document.body.classList.add('modal-open')
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open')
  }

  render() {
    const {
      type, isSubmitting,
      dialogTitle, dialogText, dialogConfirmButtonText, dialogAbortButtonText, onConfirmingDialog, onAbortingDialog,
      onDismissingConfirmation
    } = this.props

    let component

    switch (type) {
      case 'dialog': {
        component = <Dialog title={dialogTitle} text={dialogText} dialogConfirmButtonText={dialogConfirmButtonText} dialogAbortButtonText={dialogAbortButtonText} onClickingAgree={onConfirmingDialog} onClickingAbort={onAbortingDialog} isSubmitting={isSubmitting} />
        break
      }
      case 'confirmation': {
        component = <OrderConfirmation onClickingOk={onDismissingConfirmation} />
        break
      }
      case 'user-creation': {
        component = <CreateUser onClickingAbort={onAbortingDialog} onClickingOk={onConfirmingDialog} isSubmitting={isSubmitting} />
        break
      }
      default: {
        component = null
        break
      }
    }

    return (
      <div onClick={onAbortingDialog} className="modal">
        {component}
      </div>
    )
  }
}

export default Modal
