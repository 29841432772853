import React from 'react'
import classnames from 'classnames'

import './progress.sass'

const Progress = ({ steps }) =>
  <ul className="progress">
    {steps.map((child, index) =>
      <li key={index} className={classnames({ completed: child.completed, active: child.active })}>{child.component}</li>
    )}
  </ul>

export default Progress
