const formatter = value =>
  new Intl.NumberFormat('nb-no', {
    maximumFractionDigits: 0,
  }).format(value);

export const formatAmount = amount => {
  return `${formatter(amount)},-`
};

export const formatMonthlyAmount = amount => {
  return `${formatter(amount)} kr/mnd.`
};

export const formatKroners = amount => {
  return `${formatter(amount)} kr`
};
