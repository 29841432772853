import React from 'react'
import classnames from 'classnames'

import './product-list.sass'

const ProductList = ({children, selectable, checklist}) =>
  <ul className={classnames('product-list', {selectable: selectable, checklist: checklist})}>
    {children}
  </ul>

export default ProductList
