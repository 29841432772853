import { createReducers } from 'redux-arc'

import { types } from './actions'

const INITIAL_STATE = {
  requestFailed: false,
  isLoading: true,
  isCreatingUser: false,
  isDeletingId: undefined,
  deletingUserId: undefined,
  userCreateModalOpen: false,
  userDeleteModalOpen: false,
  users: []
}

const onFetchRequested = (state, _) => ({
  ...state,
  isLoading: true
})

const onRequestSucceeded = (state, action) => ({
  ...state,
  users: action.payload,
  isLoading: false
})

const onRequestFailed = (state, _) => ({
  ...state,
  requestFailed: true,
  isLoading: false
})


const onOpenUserCreateModal = (state, _) => ({
  ...state,
  userCreateModalOpen: true
})

const onCloseUserCreateModal = (state, _) => ({
  ...state,
  userCreateModalOpen: false
})

const onCreateUserRequested = (state, _) => ({
  ...state,
  isCreatingUser: true
})

const onCreateUserSucceeded = (state, action) => ({
  ...state,
  userCreateModalOpen: false,
  isCreatingUser: false,
  users: [...state.users, action.payload.user]
})

const onCreateUserFailed = (state, _) => ({
  ...state,
  userCreateModalOpen: false,
  isCreatingUser: false
})


const onOpenUserDeleteModal = (state, action) => ({
  ...state,
  userDeleteModalOpen: true,
  deletingUserId: action.payload.id,
})

const onCloseUserDeleteModal = (state, _) => ({
  ...state,
  userDeleteModalOpen: false,
  deletingUserId: undefined,
})

const onDeleteUserRequest = (state, _) => ({
  ...state,
  isDeletingId: state.deletingUserId
})

const onDeleteUserSucceeded = (state, action) => ({
  ...state,
  isDeletingId: undefined,
  userDeleteModalOpen: false,
  deletingUserId: undefined,
  users: state.users.filter(user => user.user_id !== action.payload.id)
})

const onDeleteUserFailed = (state, _) => ({
  ...state,
  isDeletingId: undefined,
  userDeleteModalOpen: false
})

const HANDLERS = {
  [types.FETCH_REQUEST]: onFetchRequested,
  [types.FETCH_SUCCEEDED]: onRequestSucceeded,
  [types.FETCH_FAILED]: onRequestFailed,

  [types.OPEN_USER_CREATE_MODAL]: onOpenUserCreateModal,
  [types.CLOSE_USER_CREATE_MODAL]: onCloseUserCreateModal,
  [types.CREATE_USER_REQUEST]: onCreateUserRequested,
  [types.CREATE_USER_SUCCEEDED]: onCreateUserSucceeded,
  [types.CREATE_USER_FAILED]: onCreateUserFailed,

  [types.OPEN_USER_DELETE_MODAL]: onOpenUserDeleteModal,
  [types.CLOSE_USER_DELETE_MODAL]: onCloseUserDeleteModal,
  [types.DELETE_USER_REQUEST]: onDeleteUserRequest,
  [types.DELETE_USER_SUCCEEDED]: onDeleteUserSucceeded,
  [types.DELETE_USER_FAILED]: onDeleteUserFailed,

  // TODO: Add error handling?
}

export default createReducers(INITIAL_STATE, HANDLERS)
