import React from 'react'

import { SUMMARY_ROUTE, DETAILS_ROUTE, relativeUrl } from '../named-routes'

import Page from '../../../shared/page/Page'
import PriceSummary from '../../../shared/price-summary/PriceSummary'
import PersonaliaForm from './personalia-form/PersonaliaForm'

const Personalia = ({ priceSummary, initialValues, savePersonalia }) =>
  <Page title="Personinfo" stickyAside>
    <Page.Main>
      <PersonaliaForm initialValues={initialValues} savePersonalia={savePersonalia} />
    </Page.Main>
    <Page.Aside>
      <PriceSummary priceSummary={priceSummary} previousStep={relativeUrl(DETAILS_ROUTE)} nextStep={relativeUrl(SUMMARY_ROUTE)} />
    </Page.Aside>
  </Page>

export default Personalia
