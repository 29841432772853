import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

const defaultSerializers = {
  marks: {
    link: props => (
      <a target="_blank" href={props.mark.href} rel="noopener noreferrer">
        {props.children}
      </a>
    ),
  },
}

class DefaultBlockContent extends React.Component {
  render() {
    const { serializers, blocks, ...other } = this.props
    if (!blocks) return null
    return (
      <BlockContent
        serializers={{ ...defaultSerializers, ...serializers }}
        blocks={blocks}
        {...other}
      />
    )
  }
}

export default DefaultBlockContent
