import React from 'react'

import ProductDescription from '../../product-description/ProductDescription'

const ProductListItem = ({children, description}) =>
  <li>
    {children}
    {description && <ProductDescription description={description} />}
  </li>

export default ProductListItem
