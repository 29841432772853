import React from 'react'
import classnames from 'classnames'

import ProductDescription from '../product-description/ProductDescription'

import { formatAmount } from '../../../utils/priceFormatting'
import { isReceiverSelected, canSelectReceiver } from '../../../state/basket-selectors'

import './edit-receivers.sass'
import { calculateKroners } from '../../../utils/pricing'

const EditReceivers = ({receivers, toggleReceiver, basket}) =>
  <ul className="edit-receivers">
    {receivers.map((receiver, receiverIndex) =>
      <li key={receiver.id}>
        <span>{receiver.name} {receiver.description && <ProductDescription description={receiver.description} />}</span>
        <ul>
          {receiver.offers.map((offer, offerIndex) =>
            offer !== undefined
              ? canSelectReceiver(basket, offerIndex)
                ? <li key={`${offer.id}-${offerIndex}`} className={classnames({ chosen: isReceiverSelected(basket, offerIndex, offer.id) })} onClick={() => toggleReceiver(offer.id, offerIndex)}>{formatAmount(calculateKroners(offer.price).amount)}</li>
                : <li key={`${offer.id}-${offerIndex}`} className="disabled">{formatAmount(calculateKroners(offer.price).amount)}</li>
              : <li key={`${receiverIndex}-${offerIndex}`} className="disabled not-selectable"></li>
          )}
        </ul>
      </li>
    )}
  </ul>

export default EditReceivers
