import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List as ListPlaceHolder } from 'react-content-loader'

import { creators as usersCreators } from '../../../../store/users/actions'

import { useAuth0 } from '../../../../utils/react-auth0-wrapper'
import { isUserAdmin, isPortalUserAdmin } from '../../../../utils/roleManagement'

import Page from '../../../shared/page/Page'
import Loader from '../../../shared/loader/Loader'

import './users.sass'

const Users = ({
  partnerName, users, isLoaded, isDeletingId,
  openUserCreateModal, usersFetchRequest, openUserDeleteModal,
}) => {
  const { user, getTokenSilently } = useAuth0()

  useEffect(() => {
    const fetchUsers = async () => {
      const token = await getTokenSilently()
      usersFetchRequest({
        email: user.email,
        token
      })
    }

    fetchUsers()
  }, [])

  if (!isLoaded) {
    return (
      <Page>
        <Page.Main>
          <ListPlaceHolder />
        </Page.Main>
      </Page>
    )
  }

  return (
    <Page>
      <Page.Main>
        <div className="users">
          <div className="heading">
            <h1>Administrer ansattilganger</h1>
            <h2>{partnerName}</h2>
          </div>

          <div className="scroll-wrapper">
            <ul className="scrollable">
              <li className="headers">
                <span className="role">Tilgang</span>
                <span className="email">E-post</span>
              </li>
              {users.length > 0 && users.map(portalUser =>
                <li key={portalUser.user_id}>
                  <h3 className="name"><img className="profile" src={portalUser.picture} alt={`Profilbilde for ${portalUser.name}`} /> {portalUser.name}</h3>
                  <span className="role">{isPortalUserAdmin(portalUser) ? 'Admin' : 'Lese'}</span>
                  <span className="email">{portalUser.email}</span>
                  <div className="actions">
                    {isUserAdmin(user) &&
                      (portalUser.user_id !== isDeletingId
                        ?  <button className="delete" onClick={() => openUserDeleteModal({ id: portalUser.user_id })}>Slett</button>
                        : <Loader />)
                    }
                  </div>
                </li>
              )}
            </ul>
          </div>
          {isUserAdmin(user) && <button className="new-user edge-btn purple" onClick={openUserCreateModal}>Ny bruker</button>}
        </div>
      </Page.Main>
    </Page>
  )
}

const mapStateToProps = (state, _) => ({
  users: state.users.users,
  isLoaded: state.users.users !== undefined,
  isDeletingId: state.users.isDeletingId,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  openUserCreateModal: usersCreators.openUserCreateModal,
  openUserDeleteModal: usersCreators.openUserDeleteModal,
  usersFetchRequest: usersCreators.fetchRequest,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Users)
