import React from 'react'

import Comparison from './comparison/Comparison'
import Page from '../../../shared/page/Page'

// Data structure is currently mirroring its twin in Episerver.
const mockData = {
  introduction: {
    title: 'Våre TV-pakker',
    body: 'FleksiPakken, SportsPakken, StrømmePakken og RiksPakken er TV-pakkene våre. Alle abonnementene åpner opp for ekstra innhold via tilleggspakker.'
  },
  subscriptionsExtras: [
    {
      offerName: "FleksiPakken",
      priceTerms: '12 mnd. binding.',
      description: 'For deg som verdsetter valgfrihet til en lav pris!'
    },
    {
      offerName: "SportsPakken",
      priceTerms: '12 mnd. binding.',
      description: 'For deg som liker sport!'
    },
    {
      offerName: "StrømmePakken",
      priceTerms: '12 mnd. binding.',
      description: 'For deg som elsker film og serier!'
    },
    {
      offerName: "RiksPakken",
      priceTerms: '12 mnd. binding.',
      description: 'For deg som ønsker et stort utvalg av film, serier og sport!',
      campaign: 'Bestselgeren'
    },
    
  ],
  conditions: '* Forutsetter 12 mnd. binding. Min. tot. 1. år for FleksiPakken: 3 948 kr., ordinærpris 449 kr/mnd. Min. tot. 1. år for SportsPakken: 4 788 kr., ordinærpris 569 kr/mnd. Min. tot. 1. år for StrømmePakken: 4 788 kr., ordinærpris 599 kr/mnd. Min. tot. 1. år for RiksPakken: 5 148 kr., ordinærpris 709 kr/mnd.'
}

const Subscriptions = ({
  subscriptionFeatures, subscriptions, basket,
  toggleSubscription
}) =>
  <Page title="Velg abonnementstype">
    <Page.Main>
      <Comparison data={mockData} subscriptionFeatures={subscriptionFeatures} subscriptions={subscriptions} toggleSubscription={toggleSubscription} basket={basket} />
    </Page.Main>
  </Page>

export default Subscriptions
