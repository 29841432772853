import React from 'react'
import classnames from 'classnames'

import { calculateKroners } from '../../../../../utils/pricing'
import { isSubscriptionSelected } from '../../../../../state/basket-selectors'

import { mapSubsciption, orderSubscriptions } from '../../../../../utils/subsciptions'
import ExternalLink from '../../../../shared/external-link/ExternalLink'

import './comparison.sass'

const Comparison = ({ data, basket, subscriptionFeatures, subscriptions, toggleSubscription }) =>
  <div className="comparison">
    <div className="matrix-variant">
      <div className="introduction rich-text">
        <h1>{data.introduction.title}</h1>
        <p>{data.introduction.body}</p>
      </div>

      <ul className="products">
        {mapSubsciption(subscriptions, data.subscriptionsExtras).map((subscription) =>
          <li key={subscription.id}>
            <div className="product">
              {subscription.campaign && <h3 className="campaign">{subscription.campaign}</h3>}

              <h2 className="name pre-heading">{subscription.name}</h2>

              <p className="price">kr {calculateKroners(subscription.price).amount}<span>/mnd.*</span></p>
              <p className="terms">{subscription.priceTerms}</p>

              <p className="description">{subscription.description}</p>

              <div className="actions" onClick={() => toggleSubscription(subscription.id)}>
                {isSubscriptionSelected(basket, subscription.id) ?
                  <button className="edge-btn purple">Valgt</button> :
                  <button className="edge-btn purple secondary">Velg pakke</button>}
              </div>
            </div>
          </li>
        )}
      </ul>

      <ul className="features">
        {subscriptionFeatures.map((feature, index) =>
          <li key={index}><h3 className={feature.icon}>{feature.name}</h3></li>
        )}
      </ul>

      <ul className="feature-indicators">
        {orderSubscriptions(subscriptions).map(subscription =>
          <li key={subscription.id}>
            <ul>
              {subscriptionFeatures.map((subscriptionFeature, subscriptionFeatureIndex) => {
                const feature = subscription.featureIndicators.find(indicator => indicator.subscriptionFeatureId === subscriptionFeature._id)
                return (
                  <li key={subscriptionFeatureIndex} className="indicator">
                    {feature && <>
                      {feature.type === 'check' && <span className="icon check"></span>}
                      {feature.type === 'text' && <span className="text">{feature.text}</span>}
                      {feature.type === 'subtleText' && <span className="text subtle">{feature.text}</span>}
                    </>
                    }
                    {!feature && <span className="text subtle">-</span>}
                  </li>
                )
              }
              )}
            </ul>
            <div className="choose">
              <div>
                <ExternalLink className="edge-btn gray-dark secondary borderless with-arrow" href={subscription.detailsUrl}>
                  Se detaljer
                </ExternalLink>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>

    <div className="compact-variant">
      <ul className="products">
        {mapSubsciption(subscriptions, data.subscriptionsExtras).map((subscription) =>
          <li key={subscription.id} className={classnames('product', { chosen: isSubscriptionSelected(basket, subscription.id) })} onClick={() => toggleSubscription(subscription.id)}>
            {subscription.campaign && <h3 className="campaign">{subscription.campaign}</h3>}

            <h2 className="name pre-heading">{subscription.name}</h2>

            <p className="price">{calculateKroners(subscription.price).amount}<span>/mnd.*</span></p>
            <p className="terms">{subscription.priceTerms}</p>

            <ul className="features">
              {subscriptionFeatures.slice(0, 2).map((subscriptionFeature, subscriptionFeatureIndex) => {
                const feature = subscription.featureIndicators.find(indicator => indicator.subscriptionFeatureId === subscriptionFeature._id)

                return (
                  <li key={subscriptionFeatureIndex}>
                    <h3 className={subscriptionFeature.icon}>{subscriptionFeature.name}</h3>

                    <div className="indicator">
                      {feature && <>
                        {feature.type === 'check' && <span className="icon check"></span>}
                        {feature.type === 'text' && <span className="text">{feature.text}</span>}
                        {feature.type === 'subtleText' && <span className="text subtle">{feature.text}</span>}
                      </>
                      }
                      {!feature && <span className="text subtle">-</span>}
                    </div>
                  </li>
                )}
              )}
            </ul>

            <div className="actions">
              <button className="edge-btn red">
                Velg
              </button>

              <ExternalLink className="edge-btn gray-dark secondary borderless with-arrow" href={subscription.detailsUrl}>
                Les mer
              </ExternalLink>
            </div>
          </li>
        )}
      </ul>
    </div>

    <div className="conditions rich-text">
      <p>{data.conditions}</p>
    </div>
  </div>

export default Comparison
