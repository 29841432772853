import { createReducers } from 'redux-arc'

import { types } from './actions'

const INITIAL_STATE = {
  salesPartner: {}
}

const onGetSalesPartnerSucceeded = (state, action) => ({
  ...state,
  salesPartner: action.payload
})

const HANDLERS = {
  [types.GET_SALES_PARTNER_SUCCEEDED]: onGetSalesPartnerSucceeded,
}

export default createReducers(INITIAL_STATE, HANDLERS)
