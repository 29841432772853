import React from 'react'

import Checkbox from '../checkbox/Checkbox'
import ProductList from '../../shared/product-list/ProductList'
import ProductListItem from '../../shared/product-list/product-list-item/ProductListItem'

import { formatMonthlyAmount } from '../../../utils/priceFormatting'
import { isPremiumSelected, isAnySubscriptionSelected } from '../../../state/basket-selectors'
import { calculateKroners } from '../../../utils/pricing';

const EditPremium = ({ selectedSubscription, premium, togglePremium, basket }) =>
  <div className="edit-premium">
    {!isAnySubscriptionSelected(basket) && <p>Du må velge abonnement først.</p>}

    {selectedSubscription && selectedSubscription.included.length >= 1 &&
      <ProductList checklist>
        {selectedSubscription && selectedSubscription.included.map(includedProduct =>
          <ProductListItem key={includedProduct.name} description={includedProduct.description}>
            {includedProduct.name}<span>(Inkludert)</span>
          </ProductListItem>
        )}
      </ProductList>
    }

    {premium && premium.length >= 1 &&
      <ProductList selectable>
        {premium.map(pickableProduct =>
          <ProductListItem key={pickableProduct.id} description={pickableProduct.description}>
            <div onClick={() => togglePremium(pickableProduct.id)}>
              <Checkbox toggled={isPremiumSelected(basket, pickableProduct.id)} />
              {pickableProduct.name} <span>({formatMonthlyAmount(calculateKroners(pickableProduct.price).amount)})</span>
            </div>
          </ProductListItem>
        )}
      </ProductList>
    }
  </div>

export default EditPremium
