import { takeLatest, call, put } from 'redux-saga/effects'

import { types as usersTypes, creators as usersCreators } from '../store/users/actions'
import { getUsers, createUser, deleteUser } from '../api/api'

function* fetchUsers(action) {
  try {
    const { email, token } = action.payload
    const response = yield call(getUsers, email, token)
    yield put(usersCreators.fetchSucceeded(response))
  } catch (error) {
    console.error(error)
    yield put(usersCreators.fetchFailed({ message: 'Kunne ikke hente brukere.' }))
  }
}

function* createUserRequest(action) {
  try {
    const { name, email, token } = action.payload
    const user = yield call(createUser, { name, email }, token)
    yield put(usersCreators.createUserSucceeded({ user: user, message: `${user.name} er lagt til, påloggingsinformasjonen er sendt på e-post.` }))
  } catch (error) {
    console.error(error)
    yield put(usersCreators.createUserFailed({ message: error.response.data ? error.response.data : 'Brukeren kunne ikke legges til.' }))
  }
}

function* deleteUserRequest(action) {
  try {
    const { id, token } = action.payload
    yield call(deleteUser, id, token)
    yield put(usersCreators.deleteUserSucceeded({ id: id, message: 'Brukeren ble slettet.' }))
  } catch (error) {
    console.error(error)
    yield put(usersCreators.deleteUserFailed({ message: 'Kunne ikke slette bruker.' }))
  }
}

function* main() {
  yield takeLatest(usersTypes.FETCH_REQUEST, fetchUsers)
  yield takeLatest(usersTypes.DELETE_USER_REQUEST, deleteUserRequest)
  yield takeLatest(usersTypes.CREATE_USER_REQUEST, createUserRequest)
  // TODO(saasen@20.09.2019): Should probably listen for DELETE_USER_SUCCEEDED and CREATE_USED_SUCCEEDED
  // TODO(saasen@20.09.2019):   to re-fetch all the users, and don't change the array in-memory as the
  // TODO(saasen@20.09.2019):   list in Auth0 might've changed while we created or deleted a user.
}

export default main
