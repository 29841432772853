import { takeEvery, delay, put } from 'redux-saga/effects'

import { types as notificationsTypes, creators as notificationsCreators } from '../store/notifications/actions'
import { types as usersTypes } from '../store/users/actions'
import { types as appTypes } from '../store/app/actions'

function* addGlobalNotificationDecay() {
  yield delay(5000)
  yield put(notificationsCreators.removeNotification())
}

function* addErrorNotification(action) {
  const { message } = action.payload
  yield put(notificationsCreators.addNotification({ message, type: 'error' }))
}

function* addSuccessNotification(action) {
  const { message } = action.payload
  yield put(notificationsCreators.addNotification({ message, type: 'success' }))
}

function* main() {
  yield takeEvery(notificationsTypes.ADD_NOTIFICATION, addGlobalNotificationDecay)
  yield takeEvery(usersTypes.CREATE_USER_FAILED, addErrorNotification)
  yield takeEvery(usersTypes.CREATE_USER_SUCCEEDED, addSuccessNotification)
  yield takeEvery(usersTypes.DELETE_USER_FAILED, addErrorNotification)
  yield takeEvery(usersTypes.DELETE_USER_SUCCEEDED, addSuccessNotification)
  yield takeEvery(usersTypes.FETCH_FAILED, addErrorNotification)
  yield takeEvery(appTypes.GET_SALES_PARTNER_FAILED, addErrorNotification)
}

export default main
