import React from 'react'
import { FormSpy } from 'react-final-form'

class AutoSave extends React.Component {
  blurOccured(currentProps, nextProps) {
    return currentProps.active && currentProps.active !== nextProps.active
  }

  componentWillReceiveProps(nextProps) {
    const { values, save } = this.props
    if (this.blurOccured(this.props, nextProps)) {
      save(values)
    }
  }

  render() {
    return null
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in save prop nicely
export default props => (
  <FormSpy
    {...props}
    subscription={{ active: true, values: true }}
    component={AutoSave}
  />
)
