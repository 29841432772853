import configuration from '../config'
import axios from 'axios'

const getDefaultHeaders = token => ({
  'Accept': 'application/json',
  'Authorization': `Bearer ${token}`
})

export const getProducts = async token => {
  const response = await get('products', token)
  return response.json()
}

export const getUsers = (email, token) => {
  return axios.get(`${configuration.apiUrl}/user/getusers/${email}`, {
    headers: getDefaultHeaders(token)
  })
    .then(response => response.data)
}

export const deleteUser = (userId, token) => {
  return axios.delete(`${configuration.apiUrl}/user/${userId}`, {
    headers: getDefaultHeaders(token)
  })
    .then(response => response.data)
}

export const createUser = (user, token) => {
  return axios.post(`${configuration.apiUrl}/user/createuser`, user, {
    headers: getDefaultHeaders(token)
  })
    .then(response => response.data)
}

export const getSalesPartner = token => {
  return axios.get(`${configuration.apiUrl}/salespartner`, {
    headers: getDefaultHeaders(token)
  })
    .then(response => response.data)
}

export const recalculateMultiroom = async (offers, token) => {
  const response = await post('multiroom/calculate', { offers }, token)
  return response.json()
}

export const submitCheckout = async (data, token) => {
  const response = await post('checkout', data, token)
  return response
}

const get = async (url, token) => {
  return await fetch(`${configuration.apiUrl}/${url}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

const post = async (url, data, token) => {
  return await fetch(`${configuration.apiUrl}/${url}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(data)
  })
}
