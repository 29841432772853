import React from 'react'

import Page from '../../../shared/page/Page'
import CustomerSearch from './customer-search/CustomerSearch'

import './home.sass'

const Home = ({ startNewSession }) =>
  <Page>
    <Page.Main>
      <div className="session-options">
        <button className="edge-btn purple" onClick={startNewSession}>Ny kunde</button>
        <CustomerSearch />
      </div>
    </Page.Main>
  </Page>

export default Home
