import moment from 'moment'
import { getOffers, isOfferTv2SportPremium } from './pickable-products-selectors'
import { calculateKroners } from '../utils/pricing'

const doesTv2SportPremiumExistInBasket = basket => basket.premium.find(offerId => isOfferTv2SportPremium(offerId)) !== undefined

export const isSubscriptionSelected = (basket, offerId) => basket.subscription === offerId
export const isAnySubscriptionSelected = basket => basket.subscription !== undefined
export const isPremiumSelected = (basket, offerId) => basket.premium.includes(offerId)
export const isReceiverSelected = (basket, index, offerId) => basket.receivers[index] === offerId
export const isAnyReceiverSelected = basket => basket.receivers.length >= 1
export const isDeliveryMethodSelected = (basket, offerId) => basket.delivery === offerId
export const isAnyDeliveryMethodSelected = basket => basket.delivery !== undefined

export const canSelectReceiver = (basket, index) => {
  if (index >= 2) {
    if (doesTv2SportPremiumExistInBasket(basket)) return false
  }

  return index <= basket.receivers.length
}
export const getSelectedSubscription = (productConfiguration, basket) =>
  basket.subscription !== undefined ? getOffers(productConfiguration).find(offer => offer.id === basket.subscription) : undefined
export const getSelectedPremium = (productConfiguration, basket) =>
  basket.premium.map(premium => getOffers(productConfiguration).find(offer => premium === offer.id))
export const getSelectedReceivers = (productConfiguration, basket) =>
  basket.receivers.map(receiver => getOffers(productConfiguration).find(offer => receiver === offer.id))
export const getSelectedDeliveryMethod = (productConfiguration, basket) =>
  basket.delivery !== undefined ? getOffers(productConfiguration).find(offer => offer.id === basket.delivery) : undefined

export const getSubscription = (productConfiguration, offerId) =>
  offerId !== undefined ? getOffers(productConfiguration).find(offer => offer.id === offerId) : undefined

export const getBasketFlattened = basket =>
  [basket.subscription, ...basket.premium, ...basket.receivers, basket.delivery].filter(x => x)

export const getPriceSummary = (productConfiguration, basket, multiroom) => {
  const offers = getBasketFlattened(basket)
    .map(offerId => getOffers(productConfiguration).find(offer => offer.id === offerId))

  // TODO(saasen@23.07.2019): We need this check for now as the price summary shouldn't be visible before chosing a subscription...
  if (offers.filter(x => x.type === 'subscription').length === 0) {
    return {
      monthlies: [],
      onceOffs: [],
      firstInvoice: 0,
      monthlyPrice: 0
    }
  }

  const containsMultiroom = multiroom && multiroom.multiroom.length > 0
  let monthlies = offers.filter(offer => offer.price.recurring)
  if (containsMultiroom) {
    const multiroomProduct = multiroom.multiroom[0]
    const multiroomPrice = multiroom.prices.find(priceOffer => priceOffer.id === multiroomProduct.id)
    const numberOfMultiroomProducts = multiroom.multiroom.length
    monthlies = [
      ...monthlies,
      {
        id: multiroomProduct.id,
        name: numberOfMultiroomProducts === 1 ? 'Multiromsavgift' : `Multiromsavgift x${numberOfMultiroomProducts}`,
        price: {
          ...multiroomPrice.price,
          amount: calculateKroners(multiroomPrice.price).amount * numberOfMultiroomProducts
        }
      }
    ]
  }

  const onceOffs = offers.filter(offer => !offer.price.recurring)
  const onceOffPrice = onceOffs.map(offer => calculateKroners(offer.price).amount).reduce((previous, current) => previous + current, 0)
  const monthlyPrice = monthlies.map(offer => calculateKroners(offer.price).amount).reduce((previous, current) => previous + current, 0)
  const numberOfDaysLeftInMonth = moment().endOf('month').diff(moment(), 'days')
  const numberOfDaysInCurrentMonth = moment().daysInMonth()
  const firstInvoice =
    (1 // Because the invoice interval is one.
    * monthlyPrice
    + onceOffPrice)
    // Plus monthly price for remaining days in the current month.
    + (monthlyPrice / numberOfDaysInCurrentMonth * numberOfDaysLeftInMonth)

  return {
    monthlies,
    onceOffs,
    firstInvoice,
    monthlyPrice
  }
}
