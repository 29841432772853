import React from 'react'

import Category from '../../../../shared/category/Category'
import EditPremium from '../../../../shared/edit-premium/EditPremium'
import EditReceivers from '../../../../shared/edit-receivers/EditReceivers'
import EditDelivery from '../../../../shared/edit-delivery/EditDelivery'

const Customization = ({
  selectedSubscription,
  premium, togglePremium,
  receivers, toggleReceiver,
  deliveryMethods, toggleDeliveryMethod,
  basket
}) =>
  <div className="customization">
    <Category title="Ekstrainnhold" expandable>
      <EditPremium selectedSubscription={selectedSubscription} premium={premium} togglePremium={togglePremium} basket={basket} />
    </Category>

    <Category title="Dekodere" expandable>
      <EditReceivers receivers={receivers} toggleReceiver={toggleReceiver} basket={basket} />
    </Category>

    <Category title="Levering" expandable>
      <EditDelivery deliveryMethods={deliveryMethods} toggleDeliveryMethod={toggleDeliveryMethod} basket={basket} />
    </Category>
  </div>

export default Customization
