import React from 'react'

import ProductList from '../../shared/product-list/ProductList'
import ProductListItem from '../../shared/product-list/product-list-item/ProductListItem'
import RadioButton from '../radiobutton/RadioButton'

import { formatKroners } from '../../../utils/priceFormatting'
import { isDeliveryMethodSelected } from '../../../state/basket-selectors'
import { calculateKroners } from '../../../utils/pricing';

const EditDelivery = ({deliveryMethods, toggleDeliveryMethod, basket}) =>
  <div className="edit-delivery">
    <ProductList selectable>
      {deliveryMethods.map(deliveryMethod =>
        <ProductListItem key={deliveryMethod.id}>
          <div onClick={() => toggleDeliveryMethod(deliveryMethod.id)}>
            <RadioButton toggled={isDeliveryMethodSelected(basket, deliveryMethod.id)}/>
            {deliveryMethod.name}<span>({formatKroners(calculateKroners(deliveryMethod.price).amount)})</span>
          </div>
        </ProductListItem>
      )}
    </ProductList>
  </div>

export default EditDelivery
