import moment from 'moment'

/*
 *  All the validators should be combined with the 'required'
 *    validator, as the other ones doesn't check for falsy values.
 */

const isEmpty = value => !value || value === undefined || value === null || value === ''

const required = name => value =>  {
  if (isEmpty(value)) {
    return `${name} er påkrevd`
  }
}

const zip = (value) => {
  if (!/^\d{4}$/.test(value)) {
    return 'Postnummer må bestå av fire tall'
  }
}

const email = (value) => {
  if (value && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value)) {
    return 'Ugyldig e-post'
  }
}

const phone = (value) => {
  if (!/^\d{8}$/.test(value)) {
    return 'Telefonnummer må bestå av åtte tall'
  }
}

// TODO(saasen@09.08.2019): Brittle date validation, but better than what we have. Let's look at this again later.
const date = (value) => {
  if (isEmpty(value)) {
    return 'Ikke korrekt dato'
  }
  // Because '.' counts...
  if (value.length !== 10) {
    return 'Må være i formatet dd.mm.åååå'
  }
  if (!moment(value, 'DD.MM.YYYY').isValid()) {
    return 'Datoen er ikke gyldig'
  }
}

const minAge = noOfYears => value => {
  const minDate = moment().subtract(noOfYears, 'y')

  if (!isEmpty(value) && !moment(value, 'DD.MM.YYYY').isBefore(minDate)) {
    return `Må være eldre enn ${noOfYears} år`
  }
}

export const validatePhone = phoneNumber =>
  required('Telefon')(phoneNumber) || phone(phoneNumber)

export const validateExternalCustomerReference = externalCustomerReference =>
  required('Kundenummer')(externalCustomerReference)

export const validateFullName = fullName =>
  required('Fullt navn')(fullName)

export const validateFirstName = firstName =>
  required('Fornavn')(firstName)

export const validateLastName = lastName =>
  required('Etternavn')(lastName)

export const validateEmail = emailAddress =>
  email(emailAddress)

export const validateRequiredEmail = emailAddress =>
  required('E-post')(emailAddress) || email(emailAddress)

export const validateBirthDate = birthDate =>
  required('Fødselsdato')(birthDate) || date(birthDate) || minAge(18)(birthDate)

export const validateZip = zipCode =>
  required('Postnummer')(zipCode) || zip(zipCode)

export const validateCity = city =>
  required('Poststed')(city)
