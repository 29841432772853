import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'

import './notifications.sass'

const Notifications = ({ notifications }) =>
  <div className="notifications">
    {notifications.length > 0 &&
      <ul>
        {notifications.map((notification, index) =>
          <li className={classnames(notification.type)} key={index}><span>{notification.message}</span></li>
        )}
      </ul>
    }
  </div>

const mapStateToProps = (state, _) => ({
  notifications: state.notifications.notifications
})

export default connect(mapStateToProps, null)(Notifications)
