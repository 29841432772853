import { validatePhone, validateExternalCustomerReference, validateFirstName, validateLastName, validateEmail, validateBirthDate, validateZip, validateCity } from '../utils/validation'

export const isSubscriptionsStepCompleted = basket => basket.subscription !== undefined
export const isDetailsStepCompleted = basket =>
  basket.subscription !== undefined &&
  basket.receivers.length >= 1 &&
  basket.delivery !== undefined

export const isPersonaliaStepCompleted = personalia =>
  !validatePhone(personalia.phone) &&
  !validateExternalCustomerReference(personalia.externalCustomerReference) &&
  !validateFirstName(personalia.firstName) &&
  !validateLastName(personalia.lastName) &&
  !validateEmail(personalia.email) &&
  !validateBirthDate(personalia.birthDate) &&
  !validateZip(personalia.zip) &&
  !validateCity(personalia.city)

export const canCheckout = (basket, personalia) =>
  isSubscriptionsStepCompleted(basket) &&
  isDetailsStepCompleted(basket) &&
  isPersonaliaStepCompleted(personalia)
