import React from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'
import classnames from 'classnames'

import InputField from '../../../shared/input-field/InputField'
import { validateFullName, validateRequiredEmail } from '../../../../utils/validation'

const focusOnError = createDecorator()
const noop = () => { }

const CreateUser = ({ onClickingAbort, onClickingOk, isSubmitting }) =>
  <div className="modal-box create-user" onClick={event => event.stopPropagation()}>
    <h2>Legg til ny bruker</h2>

    <Form onSubmit={noop} decorators={[focusOnError]}>
      {({ values, handleSubmit, valid }) =>
        <form onSubmit={handleSubmit} autoComplete="off">
          <InputField name="name" label="Navn" validate={validateFullName} />
          <InputField name="email" label="E-post" validate={validateRequiredEmail} />

          <div className="actions">
            <button className="edge-btn purple secondary" onClick={onClickingAbort}>Avbryt</button>
            <button className={classnames('edge-btn purple', { working: isSubmitting })} disabled={!valid || isSubmitting} onClick={() => onClickingOk(values)}>Fullfør</button>
          </div>
        </form>
      }
    </Form>
  </div>

export default CreateUser
