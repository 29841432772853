import React from 'react'
import classnames from 'classnames'

import './page.sass'

const Main = () => null
const Aside = () => null

class Page extends React.Component {
  static Main = Main
  static Aside = Aside

  render() {
    const { title, showSubscription, selectedSubscription, stickyAside } = this.props
    const children = React.Children.toArray(this.props.children)
    const main = children.find(child => child.type === Main)
    const aside = children.find(child => child.type === Aside)

    return (
      <div className={classnames('page', {'sticky-aside': stickyAside})}>
        {main &&
          <main>
            {title && <h1 className="title">{title}</h1>}
            {showSubscription && selectedSubscription && <h2 className="subscription">{selectedSubscription.name}</h2>}
            {main.props.children}
          </main>
        }
        {aside &&
          <aside>
            {aside.props.children}
          </aside>
        }
      </div>
    )
  }
}

export default Page
