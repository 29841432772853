import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import { formatMonthlyAmount, formatAmount } from '../../../utils/priceFormatting'

import './price-summary.sass'

const PriceSummary = ({ priceSummary, previousStep, nextStep, isSubmitting, canCheckout }) => {
  return (
    <div className="price-summary">
      <div className="scroll-wrapper">
        <ul className="categories scrollable">
          <li>
            <h3>Abonnement</h3>
            <ul className="lines">
              {priceSummary.monthlies.map((monthly, index) =>
                <li key={`${monthly.id}-${index}`}>
                  {monthly.name}<span>{formatMonthlyAmount(monthly.price.amount)}</span>

                  {monthly.included &&
                    <ul className="included">
                      {monthly.included.map(included =>
                        <li key={included.name}>{included.name}<span>Inkludert</span></li>
                      )}
                    </ul>
                  }
                </li>
              )}
            </ul>
          </li>

          <li>
            <h3>Engangskostnader</h3>
            {priceSummary.onceOffs.length > 0
              ?
              <ul className="lines">
                {priceSummary.onceOffs.map((onceOff, index) =>
                  <li key={`${onceOff.id}-${index}`}>
                    {onceOff.name}<span>{formatAmount(onceOff.price.amount)}</span>
                  </li>
                )}
              </ul>
              :
              <p>Du har ingen engangskostnader enda.</p>
            }

          </li>

          <li>
            <ul className="lines">
              <li>Første faktura<span>{formatAmount(priceSummary.firstInvoice)}</span></li>
              <li>Fast månedspris<span>{formatMonthlyAmount(priceSummary.monthlyPrice)}</span></li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="step-navigation">
        <Link className="edge-btn small purple secondary" to={previousStep}>Tilbake</Link>
        {typeof nextStep === 'function'
          ? <button className={classnames('edge-btn small purple', { working: isSubmitting })} disabled={isSubmitting || !canCheckout()} onClick={nextStep}>Fullfør bestilling</button>
          : <Link className="edge-btn small purple" to={nextStep}>Neste</Link>
        }
      </div>
    </div>
  )
}

export default withRouter(PriceSummary)
