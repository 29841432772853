import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import configuration from './config'
import { Auth0Provider } from './utils/react-auth0-wrapper'
import { useAuth0 } from './utils/react-auth0-wrapper'

import configureStore from './store/configureStore'

import App from './components/app/App'
import Loader from './components/shared/loader/Loader'
import DevTools from './components/shared/dev-tools/DevTools'

const store = configureStore()

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const AuthenticationSafeGuard = props => {
  const { isAuthenticated, loginWithRedirect, loading } = useAuth0()

  // Force authentication upon loading of you're not authenticated already.
  if (!isAuthenticated && !loading) {
    loginWithRedirect({})
  }

  return (
    <div>
      {!loading
        ? isAuthenticated
          ? <>{props.children}</>
          : null
        : <Loader fixed />
      }
    </div>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={configuration.auth0Domain}
      client_id={configuration.auth0ClientId}
      redirect_uri={configuration.auth0RedirectUri}
      audience={configuration.auth0Audience}
      onRedirectCallback={onRedirectCallback}>
      <AuthenticationSafeGuard>
        <Router>
          <App />
          <DevTools />
        </Router>
      </AuthenticationSafeGuard>
    </Auth0Provider>
  </Provider>
  , document.getElementById('root'))
