import { all } from 'redux-saga/effects'

import appSaga from '../sagas/appSaga'
import usersSaga from '../sagas/usersSaga'
import notificationsSaga from '../sagas/notificationsSaga'

export default function* rootSaga() {
  yield all([
    appSaga(),
    usersSaga(),
    notificationsSaga()
  ])
}
