import React from 'react'

import { PERSONALIA_ROUTE, relativeUrl } from '../named-routes'

import Page from '../../../shared/page/Page'
import Category from '../../../shared/category/Category'
import ProductList from '../../../shared/product-list/ProductList'
import ProductListItem from '../../../shared/product-list/product-list-item/ProductListItem'
import EditPremium from '../../../shared/edit-premium/EditPremium'
import EditReceivers from '../../../shared/edit-receivers/EditReceivers'
import EditDelivery from '../../../shared/edit-delivery/EditDelivery'
import PriceSummary from '../../../shared/price-summary/PriceSummary'
import PersonaliaForm from '../personalia/personalia-form/PersonaliaForm'

import { formatAmount, formatMonthlyAmount } from '../../../../utils/priceFormatting'
import { isAnyReceiverSelected, isAnyDeliveryMethodSelected } from '../../../../state/basket-selectors'
import { isPersonaliaStepCompleted } from '../../../../state/page-selectors'

const Summary = ({
  selectedSubscription,
  premium, togglePremium, selectedPremium,
  receivers, toggleReceiver, selectedReceivers,
  deliveryMethods, toggleDeliveryMethod, selectedDeliveryMethod,
  personalia, savePersonalia,
  basket, checkout, isSubmitting, canCheckout,
  priceSummary
}) =>
  <Page title="Sammendrag" showSubscription selectedSubscription={selectedSubscription} stickyAside>
    <Page.Main>
      <Category title="Ekstrainnhold" editable editableComponent={<EditPremium selectedSubscription={selectedSubscription} premium={premium} togglePremium={togglePremium} basket={basket} />}>
        {selectedSubscription && selectedSubscription.included.length >= 1 &&
          <ProductList checklist>
            {selectedSubscription && selectedSubscription.included.map(includedProduct =>
              <ProductListItem key={includedProduct.name} description={includedProduct.description}>
                {includedProduct.name}<span>(Inkludert)</span>
              </ProductListItem>
            )}
          </ProductList>
        }

        {selectedPremium && selectedPremium.length >= 1 &&
          <ProductList checklist>
            {selectedPremium.map(premiumEntry =>
              <ProductListItem key={premiumEntry.id} description={premiumEntry.description}>
                {premiumEntry.name} <span>({formatMonthlyAmount(premiumEntry.price.amount)})</span>
              </ProductListItem>
            )}
          </ProductList>
        }

        {(selectedPremium && selectedPremium.length < 1) && (selectedSubscription && selectedSubscription.included.length < 1) &&
          <p>Ekstrainnhold ikke valgt.</p>
        }
      </Category>

      <Category title="Dekodere" invalid={!isAnyReceiverSelected(basket)} invalidErrorMessage="Du må velge minst én dekoder." editable editableComponent={<EditReceivers receivers={receivers} toggleReceiver={toggleReceiver} basket={basket} />}>
        <ProductList checklist>
          {selectedReceivers.map((receiver, index) =>
            <ProductListItem key={`${receiver.id}-${index}`} description={receiver.description}>
              {receiver.name} <span>({formatAmount(receiver.price.amount)})</span>
            </ProductListItem>
          )}
        </ProductList>
      </Category>

      <Category title="Levering" invalid={!isAnyDeliveryMethodSelected(basket)} invalidErrorMessage="Du må velge leveringsmetode." editable editableComponent={<EditDelivery deliveryMethods={deliveryMethods} toggleDeliveryMethod={toggleDeliveryMethod} basket={basket} />}>
        <ProductList checklist>
          {selectedDeliveryMethod &&
            <ProductListItem key={selectedDeliveryMethod.id}>
              {selectedDeliveryMethod.name} <span>({formatAmount(selectedDeliveryMethod.price.amount)})</span>
            </ProductListItem>
          }
        </ProductList>
      </Category>

      <Category title="Om kunden" invalid={!isPersonaliaStepCompleted(personalia)} invalidErrorMessage="Du må fylle ut personalia." editable editableComponent={<PersonaliaForm initialValues={personalia} savePersonalia={savePersonalia} />}>
        <ul className="personalia-preview">
          <li>
            <h4>Telefon:</h4>
            <p>{personalia.phone}</p>
          </li>

          <li>
            <h4>Kundenummer:</h4>
            <p>{personalia.externalCustomerReference}</p>
          </li>

          <li>
            <h4>Fornavn:</h4>
            <p>{personalia.firstName}</p>
          </li>

          <li>
            <h4>Etternavn:</h4>
            <p>{personalia.lastName}</p>
          </li>

          <li>
            <h4>E-post:</h4>
            <p>{personalia.email}</p>
          </li>

          <li>
            <h4>Fødselsdato:</h4>
            <p>{personalia.birthDate}</p>
          </li>

          <li>
            <h4>Adresse:</h4>
            <p>
              {personalia.address}
              <br />
              {personalia.zip} {personalia.city}
            </p>
          </li>
        </ul>
      </Category>
    </Page.Main>
    <Page.Aside>
      <PriceSummary priceSummary={priceSummary} previousStep={relativeUrl(PERSONALIA_ROUTE)} nextStep={checkout} isSubmitting={isSubmitting} canCheckout={canCheckout} />
    </Page.Aside>
  </Page>

export default Summary
