import { createActions } from 'redux-arc'

export const { types, creators } = createActions('users', {
  fetchRequest: null,
  fetchSucceeded: null,
  fetchFailed: null,
  deleteUserRequest: null,
  deleteUserSucceeded: null,
  deleteUserFailed: null,
  openUserCreateModal: null,
  closeUserCreateModal: null,
  openUserDeleteModal: null,
  closeUserDeleteModal: null,
  createUserRequest: null,
  createUserSucceeded: null,
  createUserFailed: null
})
