import React from 'react'

import { SUBSCRIPTIONS_ROUTE, PERSONALIA_ROUTE, relativeUrl } from '../named-routes'

import Page from '../../../shared/page/Page'
import PriceSummary from '../../../shared/price-summary/PriceSummary'
import Customization from './customization/Customization'

const Details = ({
  selectedSubscription,
  premium, togglePremium,
  receivers, toggleReceiver,
  deliveryMethods, toggleDeliveryMethod,
  basket,
  priceSummary
}) =>
  <Page title="Detaljér tilbudet" showSubscription selectedSubscription={selectedSubscription} stickyAside>
    <Page.Main>
      <Customization
        selectedSubscription={selectedSubscription}
        premium={premium} togglePremium={togglePremium}
        receivers={receivers} toggleReceiver={toggleReceiver}
        deliveryMethods={deliveryMethods} toggleDeliveryMethod={toggleDeliveryMethod}
        basket={basket}
      />
    </Page.Main>
    <Page.Aside>
      <PriceSummary priceSummary={priceSummary} previousStep={relativeUrl(SUBSCRIPTIONS_ROUTE)} nextStep={relativeUrl(PERSONALIA_ROUTE)} />
    </Page.Aside>
  </Page>

export default Details
