import { getSelectedSubscription } from './basket-selectors'

export const getOffers = (productConfiguration) => Object.entries(productConfiguration.offers).reduce((offers, [id, offer]) => {
  offers = [...offers, {
    id,
    ...offer,
    included: offer.type === 'subscription' && offer.included
      ? offer.included.map(includedProduct => Object.entries(productConfiguration.offers).filter(x => getCommercialProductId(x[0]) === includedProduct)[0]).map(value => ({ id: getCommercialProductId(value[0]), ...value[1] }) )
      : []
  }]
  return offers
}, [])

const getCommercialProductId = offerId => offerId.split('-')[0]
export const isOfferTv2SportPremium = offerId => getCommercialProductId(offerId) === '9'

export const getSubscriptions = (productConfiguration) => getOffers(productConfiguration).filter(offer => offer.type === 'subscription')
export const getPremium = (basket, productConfiguration) => {
  const selectedSubscription = getSelectedSubscription(productConfiguration, basket)
  if (!selectedSubscription || !selectedSubscription.optionalExtras) return []

  return getOffers(productConfiguration)
    .filter(offer => selectedSubscription.optionalExtras.includes(offer.id))
}
export const getReceivers = productConfiguration => {
  const allReceiverOffers = getOffers(productConfiguration).filter(offer => offer.type === 'receiver')
  const configuredReceivers = productConfiguration.receivers
  const uniqueReceiverCommercialProductIds = [...new Set([].concat.apply([], configuredReceivers).map(offer => getCommercialProductId(offer)))]

  const receiverMatrix = uniqueReceiverCommercialProductIds.map(uniqueReceiver => {
    const receiverOffers = configuredReceivers.map((_, configuredIndex) => {
      const receiverConfigurationExists = configuredReceivers[configuredIndex].find(offer => offer ? getCommercialProductId(offer) === uniqueReceiver : false)
      if (!receiverConfigurationExists) {
        return undefined
      }
      return allReceiverOffers.find(offer => offer.id === receiverConfigurationExists)
    })

    return {
      id: uniqueReceiver,
      name: allReceiverOffers.filter(offer => getCommercialProductId(offer.id) === uniqueReceiver)[0].name,
      description: allReceiverOffers.filter(offer => getCommercialProductId(offer.id) === uniqueReceiver)[0].description,
      offers: receiverOffers
    }
  })

  return receiverMatrix
}
export const getDeliveryMethods = (productConfiguration) => getOffers(productConfiguration).filter(offer => offer.type === 'shipping')
