import React from 'react'
import classnames from 'classnames'

import './loader.sass'

const Loader = ({ fixed }) =>
  <div className={classnames('loader', { fixed: fixed })}>
    Laster siden...
  </div>

export default Loader
