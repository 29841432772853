export const mapSubsciption = function(subscriptionList, subsciptionExtrasList) {
  var subscriptions = [];
  subscriptionList.forEach((sub) => {
    var result = subsciptionExtrasList.filter(x => x.offerName === sub.name);
    if (result.length > 0) {
        subscriptions.push({...sub, ...result[0]})
    }
  });

  return orderSubscriptions(subscriptions);
}

export const orderSubscriptions = function(subscriptions) {
  return subscriptions.sort(function(a, b) {
    var adjustedFirstPrice = a.price.offerType === 'Reduced' ? a.price.amount - (a.price.savingsFirstYear / 12) : a.price.amount;
    var adjustedSecondPrice = b.price.offerType === 'Reduced' ? b.price.amount - (b.price.savingsFirstYear / 12) : b.price.amount;
    return parseFloat(adjustedFirstPrice) - parseFloat(adjustedSecondPrice);
  });
}