import React from 'react'

import DefaultBlockContent from '../default-block-content/DefaultBlockContent'

import './product-description.sass'

const ProductDescription = ({description}) =>
  <>
    <button className="show-product-description"></button>
    <DefaultBlockContent
      className="product-description rich-text"
      blocks={description}
      renderContainerOnSingleChild
    />
  </>

export default ProductDescription
