import React from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-focus'

import { validatePhone, validateExternalCustomerReference, validateFirstName, validateLastName, validateEmail, validateBirthDate, validateZip, validateCity } from '../../../../../utils/validation'

import InputField from '../../../../shared/input-field/InputField'
import AutoSave from './auto-save/AutoSave'

import './personalia-form.sass'

const focusOnError = createDecorator()
const noop = () => { }

class PersonaliaForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: props.initialValues || {}
    }
  }

  render() {
    const { initialValues, savePersonalia } = this.props

    return (
      <Form onSubmit={noop} decorators={[focusOnError]} initialValues={initialValues}>
        {() =>
          <form className="personalia-form" autoComplete="off">
            <AutoSave save={savePersonalia} />

            <div className="dual">
              <InputField name="phone" label="Telefon" type="tel" validate={validatePhone} />
              <InputField name="externalCustomerReference" label="Kundenummer" validate={validateExternalCustomerReference} />
            </div>

            <div className="dual">
              <InputField name="firstName" label="Fornavn" validate={validateFirstName} />
              <InputField name="lastName" label="Etternavn" validate={validateLastName} />
            </div>

            <div className="short">
              <InputField name="email" label="E-post" type="email" validate={validateEmail} />
            </div>

            <div className="short">
              <InputField name="birthDate" label="Fødselsdato (dd.mm.åååå)" validate={validateBirthDate} />
            </div>

            <hr />

            <InputField name="address" label="Adresse" />

            <div className="dual">
              <InputField name="zip" label="Postnummer" validate={validateZip} />
              <InputField name="city" label="Poststed" validate={validateCity} />
            </div>
          </form>
        }
      </Form>
    )
  }
}

export default PersonaliaForm
