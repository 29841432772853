import React from 'react'

import AnimatedCheckmark from './animated-checkmark/AnimatedCheckmark'

const OrderConfirmation = ({ onClickingOk }) =>
  <div className="modal-box order-confirmation" onClick={event => event.stopPropagation()}>
    <div>
      <h2>Bestillingen er fullført!</h2>
      <p>Ordrebekreftelse vil bli sendt til kundens e-postadresse.</p>
      <button className="close" onClick={onClickingOk}>Lukk</button>

      <AnimatedCheckmark />
    </div>
  </div>

export default OrderConfirmation
