import config from '../../../config'

let DevTools = () => null

if (config.isDevelopment) {
  const React = require('react')
  const ReduxDevTools = require('redux-devtools')
  const DockMonitor = require('redux-devtools-dock-monitor').default
  const LogMonitor = require('redux-devtools-log-monitor').default
  const SliderMonitor = require('redux-slider-monitor').default

  DevTools = ReduxDevTools.createDevTools(
    <DockMonitor
      toggleVisibilityKey="ctrl-h"
      changePositionKey="ctrl-q"
      changeMonitorKey="ctrl-m"
      defaultIsVisible={false}>
      <LogMonitor />
      <SliderMonitor />
    </DockMonitor>
  )
}

export default DevTools
