const configuration = {
  apiUrl: process.env.REACT_APP_API_URL || '/api',
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID || '',
  auth0RedirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI || 'http://localhost:3000',
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  isDevelopment: process.env.NODE_ENV !== 'production',
}

export default configuration
