const REDUCED_OFFER_TYPE = 'Reduced'

export const calculateKroners = price => {
  switch (price.offerType) {
  case REDUCED_OFFER_TYPE: {
    if (price.recurring) {
      return {
        amount: price.amount - (price.savingsFirstYear / 12),
        originalPrice: price.amount,
        recurring: price.recurring
      }
    } else {
      return {
        amount: price.amount,
        originalPrice: price.amount + price.savingsFirstYear,
        recurring: price.recurring
      }
    }
  }
  default: {
    if (price.recurring) {
      return {
        amount: price.amount,
        recurring: price.recurring
      }
    } else {
      return {
        amount: price.amount,
        recurring: price.recurring
      }
    }
  }
  }
}
