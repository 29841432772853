import React from 'react'

import Page from '../../../shared/page/Page'
import ExternalLink from '../../../shared/external-link/ExternalLink'

import './resources.sass'

const Resources = ({partnerName}) =>
  <Page>
    <Page.Main>
      <div className="resources">
        <div className="introduction">
          <h1>Eksisterende kunder</h1>
          <p>Noen nyttige lenker for å håndtere eksisterende kundeforhold.</p>
        </div>

        <div className="links rich-text">
          <ul>
            <li>Rediger eksisterende kundeforhold: <ExternalLink href="https://web-salespartner-customercare.rikstv.no/">https://web-salespartner-customercare.rikstv.no/</ExternalLink></li>
            <li>                    
              Kundeservice sitt intranett:
              {/* TODO(jsirevag@20.11.2019): This is quick and dirty. Get url from salespartner endpoint.*/}
              {{ 
                'GP Dataservice': <ExternalLink href="https://www.rikstv.no/ksweb/partner-kswebgp/">https://www.rikstv.no/ksweb/partner-kswebgp/</ExternalLink>,
                'RealNett': <ExternalLink href="https://www.rikstv.no/ksweb/partner-kswebrealnett/">https://www.rikstv.no/ksweb/partner-kswebrealnett/</ExternalLink>,
                'Nettstar': <ExternalLink href="https://www.rikstv.no/ksweb/partner-kswebnettstar/">https://www.rikstv.no/ksweb/partner-kswebnettstar/</ExternalLink>                
              }[partnerName] || <ExternalLink href="https://www.rikstv.no/ksweb/partner-ksweb/">https://www.rikstv.no/ksweb/partner-ksweb/</ExternalLink>}              
            </li>
            <li>Feilsøk: <ExternalLink href="https://ksdash.rikstv.no/">https://ksdash.rikstv.no/</ExternalLink></li>
          </ul>
        </div>
      </div>
    </Page.Main>
  </Page>

export default Resources
