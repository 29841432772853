import { createReducers } from 'redux-arc'

import { types } from './actions'

const INITIAL_STATE = {
  notifications: []
}

const onAddingNotification = (state, action) => ({
  ...state,
  notifications: [...state.notifications, action.payload]
})

const onRemovingNotification = (state, _) => ({
  ...state,
  notifications: state.notifications.slice(1)
})

const HANDLERS = {
  [types.ADD_NOTIFICATION]: onAddingNotification,
  [types.REMOVE_NOTIFICATION]: onRemovingNotification,
}

export default createReducers(INITIAL_STATE, HANDLERS)
