import React from 'react'
import { Link, withRouter, matchPath } from 'react-router-dom'
import classnames from 'classnames'

import { isSubscriptionsStepCompleted, isDetailsStepCompleted, isPersonaliaStepCompleted, canCheckout } from '../../../state/page-selectors'
import { SUBSCRIPTIONS_ROUTE, DETAILS_ROUTE, PERSONALIA_ROUTE, SUMMARY_ROUTE, RESOURCES_ROUTE, relativeUrl } from '../router/named-routes'

import Progress from './progress/Progress'
import Profile from './profile/Profile'
import Notifications from './notifications/Notifications'

import './header.sass'

function Header({
  sessionExists, basket, personalia, location, history, onClickingAbortSession,
  user, logout
}) {
  if (
    matchPath(location.pathname, relativeUrl(SUBSCRIPTIONS_ROUTE)) ||
    matchPath(location.pathname, relativeUrl(DETAILS_ROUTE)) ||
    matchPath(location.pathname, relativeUrl(PERSONALIA_ROUTE)) ||
    matchPath(location.pathname, relativeUrl(SUMMARY_ROUTE))) {
    const navigate = route => {
      if (!matchPath(location.pathname, route)) {
        history.push(route)
      }
    }

    const steps = [
      {
        completed: isSubscriptionsStepCompleted(basket),
        active: matchPath(location.pathname, relativeUrl(SUBSCRIPTIONS_ROUTE)),
        component: <button disabled={!sessionExists} onClick={() => navigate(relativeUrl(SUBSCRIPTIONS_ROUTE))}>Abonnement</button>
      },
      {
        completed: isDetailsStepCompleted(basket),
        active: matchPath(location.pathname, relativeUrl(DETAILS_ROUTE)),
        component: <button disabled={!isSubscriptionsStepCompleted(basket)} onClick={() => navigate(relativeUrl(DETAILS_ROUTE))}>Detaljer</button>
      },
      {
        completed: isPersonaliaStepCompleted(personalia),
        active: matchPath(location.pathname, relativeUrl(PERSONALIA_ROUTE)),
        disabled: !isSubscriptionsStepCompleted(basket),
        component: <button disabled={!isSubscriptionsStepCompleted(basket)} onClick={() => navigate(relativeUrl(PERSONALIA_ROUTE))}>Om kunden</button>
      },
      {
        completed: canCheckout(basket, personalia),
        active: matchPath(location.pathname, relativeUrl(SUMMARY_ROUTE)),
        disabled: !isSubscriptionsStepCompleted(basket),
        component: <button disabled={!isSubscriptionsStepCompleted(basket)} onClick={() => navigate(relativeUrl(SUMMARY_ROUTE))}>Sammendrag</button>
      }
    ]

    return (
      <header className="steps">
        <div className="contain-wrapper">
          <Link className="logo" to="/" />

          <Progress steps={steps} />

          <button className="abort-session" onClick={onClickingAbortSession}>
            Avbryt sesjonen
          </button>
        </div>

        <Notifications />
      </header>
    )
  } else {
    return (
      <header className="top-level">
        <div className="contain-wrapper">
          <Link className="logo" to="/" />

          <ul className="top-level-navigation">
            <li><Link className={classnames('nav-button', {active: matchPath(location.pathname, relativeUrl('/'))})} to="/">Nytt salg</Link></li>
            <li><Link className={classnames('nav-button', {active: matchPath(location.pathname, relativeUrl(RESOURCES_ROUTE))})} to={relativeUrl(RESOURCES_ROUTE)}>Eksisterende kunder</Link></li>
          </ul>

          <Profile name={user.name} onClickingLogout={logout} />
        </div>

        <Notifications />
      </header>
    )
  }
}

export default withRouter(Header)
