import React, {useState} from 'react'
import classnames from 'classnames'

import './category.sass'

const Category = ({ title, invalid, invalidErrorMessage, expandable, initiallyExpanded, editable, editableComponent, children }) => {
  const [expanded, toggleExpanded] = useState(initiallyExpanded)
  const [editing, toggleEditing] = useState(false)

  const clickHandler = (e) => {
    const targetClass = e.target.classList[0]
    e.stopPropagation()

    if (expandable && editable) {
      if (targetClass === 'toggle-expanded') {
        toggleExpanded(!expanded)
      } else if (targetClass === 'toggle-editing') {
        toggleEditing(!editing)
      } else {
        return
      }
    } else if (expandable) {
      toggleExpanded(!expanded)
    } else if (editable) {
      toggleEditing(!editing)
    }
  }

  return (
    <div className={classnames('category', {invalid: invalid, expandable: expandable, expanded: expanded, editable: editable, editing: editing })}>
      <div className={classnames('head', {clickable: (editable || expandable) && !invalid})} onClick={clickHandler}>
        <h2 className="title">{title}</h2>

        {(expandable || editable) &&
          <div className="actions">
            {expandable && <button className="toggle-expanded">Toggle expanded</button>}
            {editable && !editing && <button className="toggle-editing">Toggle editing</button>}
          </div>
        }
      </div>

      {(!expandable || expanded) &&
        <div className="content">
          {((!expandable && !editing) || (!editing && expanded)) && !invalid &&
            <div className="show">
              {children}
            </div>
          }

          {editing &&
            <div className="edit">
              {editableComponent}
            </div>
          }

          {invalid && !editing && <p className="error">{invalidErrorMessage}</p>}
        </div>
      }

      {editing && <button className="edge-btn purple toggle-editing" onClick={clickHandler}>Ferdig</button>}
    </div>
  )}

export default Category
