import React from 'react'
import classnames from 'classnames'

const Dialog = ({ title, text, dialogConfirmButtonText, dialogAbortButtonText, onClickingAgree, onClickingAbort, isSubmitting }) =>
  <div className="modal-box" onClick={event => event.stopPropagation()}>
    <h2>{title}</h2>
    <p>{text}</p>

    <div className="actions">
      <button className="edge-btn purple secondary" onClick={onClickingAbort}>{dialogAbortButtonText ? dialogAbortButtonText : 'Avbryt'}</button>
      <button className={classnames('edge-btn purple', { working: isSubmitting })} disabled={isSubmitting} onClick={onClickingAgree}>{dialogConfirmButtonText ? dialogConfirmButtonText : 'OK'}</button>
    </div>
  </div>

export default Dialog
