import { combineReducers } from 'redux'
import app from './app/reducers'
import users from './users/reducers'
import notifications from './notifications/reducers'

const rootReducer = combineReducers({
  app,
  users,
  notifications,
})

export default rootReducer
