import React from 'react'

import './customer-search.sass'

const CustomerSearch = () =>
  <div className="customer-search">
    <input placeholder="Utkast eller eksisterende kunder"></input>
  </div>

export default CustomerSearch
