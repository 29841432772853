import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { USERS_ROUTE } from '../../router/named-routes'

import './profile.sass'

const Profile = ({ name, onClickingLogout }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)
  const onClickingMenuItem = () => setMenuOpen(false)

  return (
    <div className="profile">
      <p className="name">{name}</p>
      <button className="toggle-menu" onClick={toggleMenu}>Åpne/lukke meny</button>

      {menuOpen && <>
        <div className="click-field" onClick={toggleMenu}></div>
        <ul className="menu">
          <li><Link to={USERS_ROUTE} onClick={onClickingMenuItem}>Brukertilgang</Link></li>
          {/* If we remove this arrow function, the logout will cause an endless loop. We don't know why. */}
          <li><button onClick={() => onClickingLogout()}>Logg ut</button></li>
        </ul>
      </>}
    </div>
  )
}

export default Profile
