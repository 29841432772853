import { takeLatest, call, put } from 'redux-saga/effects'

import { types as appTypes, creators as appCreators } from '../store/app/actions'
import { getSalesPartner } from '../api/api'

function* fetchSalesPartner(action) {
  try {
    const { token } = action.payload
    const response = yield call(getSalesPartner, token)
    yield put(appCreators.getSalesPartnerSucceeded(response))
  } catch (error) {
    console.error(error)
    yield put(appCreators.getSalesPartnerFailed({ message: 'Kunne ikke hente data for salgspartner.' }))
  }
}


function* main() {
  yield takeLatest(appTypes.GET_SALES_PARTNER_REQUEST, fetchSalesPartner)
}

export default main
