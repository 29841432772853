import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import DevTools from '../components/shared/dev-tools/DevTools'
import rootReducer from './root.reducer'
import rootSaga from './root.saga'
import config from '../config'

const devTools = () => {
  if (config.isDevelopment) {
    return DevTools.instrument()
  }
  return id => id
}

const sagaMiddleware = createSagaMiddleware()

const configureStore = initialState => {
  const middlewares = [sagaMiddleware]

  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(...middlewares),
      devTools()
    )
  )

  if (config.isDevelopment) {
    if (module.hot) {
      module.hot.accept('./root.reducer', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }

  sagaMiddleware.run(rootSaga)

  return store
}


export default configureStore
