import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import useMethods from 'use-methods'
import { Switch, Route, Link, withRouter } from 'react-router-dom'
import moment from 'moment'

import { useAuth0 } from '../../utils/react-auth0-wrapper'
import { getProducts, submitCheckout, recalculateMultiroom } from '../../api/api'
import { canCheckout } from '../../state/page-selectors'
import { getSelectedSubscription, getSelectedPremium, getSelectedReceivers, getSelectedDeliveryMethod, getBasketFlattened, getPriceSummary, getSubscription } from '../../state/basket-selectors'
import { getSubscriptions, getPremium, getReceivers, getDeliveryMethods, isOfferTv2SportPremium } from '../../state/pickable-products-selectors'
import { SUBSCRIPTIONS_ROUTE, DETAILS_ROUTE, PERSONALIA_ROUTE, SUMMARY_ROUTE, RESOURCES_ROUTE, USERS_ROUTE, relativeUrl } from './router/named-routes'
import { creators as usersCreators } from '../../store/users/actions'
import { creators as appCreators } from '../../store/app/actions'

import Loader from '../shared/loader/Loader'
import Page from '../shared/page/Page'
import Header from './header/Header'
import Modal from './modal/Modal'
import Home from './router/home/Home'
import Resources from './router/resources/Resources'
import Subscriptions from './router/subscriptions/Subscriptions'
import Details from './router/details/Details'
import Personalia from './router/personalia/Personalia'
import Summary from './router/summary/Summary'
import Users from './router/users/Users'

import './app.sass'

const localStorageInitialState = {
  version: '1.0.0',
  basket: {
    permium: [],
    receivers: []
  },
  multiroom: undefined,
  personalia: {}
}

const initialState = {
  basket: {
    premium: [],
    receivers: [],
  },
  showSubscriptionChangeModal: false,
  showOrderConfirmationModal: false,
  showOrderErrorModal: false,
  showAbortSessionModal: false,
  multiroom: undefined,
  personalia: {},
}
const initialProductsState = {}
const initialSubscriptionFeaturesState = []

const reducers = state => ({
  clearState() {
    state.basket = initialState.basket
    state.multiroom = initialState.multiroom
    state.personalia = initialState.personalia
  },
  toggleSubscription(offerId, history) {
    if (state.basket.subscription !== offerId) {
      state.basket.subscription = offerId
      state.basket.premium = []
      state.basket.receivers = []
      state.basket.delivery = undefined
      state.showSubscriptionChangeModal = false
      history.push(relativeUrl(DETAILS_ROUTE))
    }
  },
  togglePremium(offerId) {
    if (state.basket.premium.includes(offerId)) {
      state.basket.premium = state.basket.premium.filter(x => x !== offerId)
    } else {
      state.basket.premium = [...state.basket.premium, offerId]
      // TODO(saasen@27.09.2019): If we need to handle this for more products, we should probably add this to the core logic.
      if (isOfferTv2SportPremium(offerId)) {
        state.basket.receivers = state.basket.receivers.slice(0, 2)
      }
    }
  },
  toggleReceiver(offerId, index) {
    const currentLength = state.basket.receivers.length
    const receivers = state.basket.receivers

    if (currentLength === index) {
      // New element at the end of the list
      state.basket.receivers = [...receivers, offerId]
    } else if (receivers[index] !== offerId) {
      // Replace an existing item in the list
      state.basket.receivers[index] = offerId
    } else {
      // De-select an item in the "matrix", but at the same time remove everything after the specified index,
      //  as it wouldn't make sense to de-select an item in the middle of the list. Therefore we de-select
      //  everything after the specified index
      state.basket.receivers = receivers.slice(0, index)
    }
  },
  toggleDeliveryMethod(offerId) {
    if (state.basket.delivery !== offerId) {
      state.basket.delivery = offerId
    }
  },
  loadBasket(basket) {
    state.basket.subscription = basket.subscription
    state.basket.premium = basket.premium ? basket.premium : []
    state.basket.receivers = basket.receivers ? basket.receivers : []
    state.basket.delivery = basket.delivery
  },
  toggleSubscriptionChange(offerId, history) {
    if (state.basket.subscription === undefined) {
      this.toggleSubscription(offerId, history)
    } else if (offerId !== state.basket.subscription) {
      state.subscriptionChangeOfferId = offerId
      state.showSubscriptionChangeModal = true
    } else {
      history.push(relativeUrl(DETAILS_ROUTE))
    }
  },
  closeSubscriptionChangeModal() {
    state.showSubscriptionChangeModal = false
  },
  promptOrderConfirmationModal() {
    state.showOrderConfirmationModal = true
  },
  closeOrderConfirmationModal() {
    state.showOrderConfirmationModal = false
  },
  promptOrderErrorModal() {
    state.showOrderErrorModal = true
  },
  closeOrderErrorModal() {
    state.showOrderErrorModal = false
  },
  promptAbortSessionModal() {
    state.showAbortSessionModal = true
  },
  closeAbortSessionModal() {
    state.showAbortSessionModal = false
  },
  promptAbortFlowModal() {
    state.showAbortFlowModal = true
  },
  closeAbortFlowModal() {
    state.showAbortFlowModal = false
  },
  promptCreateUserModal() {
    state.showCreateUserModal = true
  },
  closeCreateUserModal() {
    state.showCreateUserModal = false
  },
  setMultiroom(multiroom) {
    state.multiroom = multiroom
  },
  savePersonalia(personalia) {
    state.personalia = {
      ...personalia
    }
  },
  loadPersonalia(personalia) {
    state.personalia = personalia
  }
})

const localStorageSessionKey = 'ecommerce-session'

const doesSessionExist = () => {
  return localStorage.getItem(localStorageSessionKey)
}

const storeStateToLocalStorage = state => {
  const session = doesSessionExist()
  if (session) {
    localStorage.setItem(localStorageSessionKey, JSON.stringify(state))
  }
}

const App = ({
  history, showCreateUserModal, showDeleteUserModal, deletingUserId, isDeletingUser, isCreatingUser, salesPartner,
  createUserRequest, closeUserCreateModal,
  deleteUserRequest, closeUserDeleteModal,
  getSalesPartnerRequest,
}) => {
  const { getTokenSilently, user, logout } = useAuth0()
  const [token, setToken] = useState('')
  const [
    {
      basket,
      subscriptionChangeOfferId, showSubscriptionChangeModal,
      showOrderConfirmationModal,
      showOrderErrorModal,
      showAbortSessionModal,
      showAbortFlowModal,
      multiroom,
      personalia,
    },
    {
      clearState,
      toggleSubscription, togglePremium, toggleReceiver, toggleDeliveryMethod, loadBasket,
      toggleSubscriptionChange, closeSubscriptionChangeModal,
      promptOrderConfirmationModal, closeOrderConfirmationModal,
      promptOrderErrorModal, closeOrderErrorModal,
      promptAbortSessionModal, closeAbortSessionModal,
      promptAbortFlowModal, closeAbortFlowModal,
      setMultiroom,
      savePersonalia, loadPersonalia,
    }
  ] = useMethods(reducers, initialState)
  const [productConfiguration, mutateProducts] = useState(initialProductsState)
  const [subscriptionFeatures, mutateSubscriptionFeatures] = useState(initialSubscriptionFeaturesState)
  const [isLoaded, setInitialLoad] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sessionExists, setSession] = useState(false)

  const loadStateFromLocalStorage = () => {
    const sessionAlreadyExists = doesSessionExist()
    if (sessionAlreadyExists) {
      const session = JSON.parse(localStorage.getItem(localStorageSessionKey))
      loadBasket(session.basket)
      loadPersonalia(session.personalia)
      setSession(true)
    }
  }

  const startNewSessionConfirmation = () => {
    setSession(true)
    localStorage.setItem(localStorageSessionKey, JSON.stringify(localStorageInitialState))
    clearState()
    closeAbortSessionModal()
    history.push(SUBSCRIPTIONS_ROUTE)
  }

  const abortFlowAndResetState = () => {
    removeSession()
    clearState()
    closeAbortFlowModal()
    closeOrderErrorModal()
    history.push('/')
  }

  const closeAbortSessionAndRedirectToSubscriptions = () => {
    closeAbortSessionModal()
    history.push(relativeUrl(SUBSCRIPTIONS_ROUTE))
  }

  const removeSession = () => {
    localStorage.removeItem(localStorageSessionKey)
    setSession(false)
  }

  const startNewSession = () => {
    const existingSession = doesSessionExist()
    if (existingSession) {
      const session = JSON.parse(localStorage.getItem(localStorageSessionKey))
      if (session.basket.subscription) {
        promptAbortSessionModal()
      } else {
        history.push(relativeUrl(SUBSCRIPTIONS_ROUTE))
      }
    } else {
      setSession(true)
      history.push(relativeUrl(SUBSCRIPTIONS_ROUTE))
      localStorage.setItem(localStorageSessionKey, JSON.stringify(localStorageInitialState))
    }
  }

  // TODO(saasen@11.07.2019): Show some error message on: validation errors, submit errors etc.?
  const checkout = async () => {
    setIsSubmitting(true)
    const token = await getTokenSilently()
    const formattedPersonalia = {
      birthDate: moment(personalia.birthDate, 'DDMMYYYY').format()
    }
    try {
      const response = await submitCheckout({
        ...basket,
        ...personalia,
        ...formattedPersonalia
      }, token)
      if (response.ok) {
        removeSession()
        clearState()
        history.push('/')
        promptOrderConfirmationModal()
      } else {
        throw new Error(`${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      promptOrderErrorModal()
      console.log('Error: ', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const fetchSalesPartner = async () => {
      const token = await getTokenSilently()
      getSalesPartnerRequest({ token })
    }

    fetchSalesPartner()
  }, [])

  useEffect(() => {
    const getToken = async () => {
      const bearerToken = await getTokenSilently()
      setToken(bearerToken)
    }

    getToken()
  }, [])

  useEffect(() => {
    loadStateFromLocalStorage()
  // JUSTIFICATION: Endless loop if we include the dependency. I may have misunderstood something here...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchProducts = async () => {
      const token = await getTokenSilently()
      const { partnerId, offers, receivers, subscriptionFeatures } = await getProducts(token)
      let subscriptionFeaturesOrdered = subscriptionFeatures.sort((a, b) => a.priority - b.priority)
      mutateProducts({
        partnerId,
        offers,
        receivers
      })
      mutateSubscriptionFeatures(subscriptionFeaturesOrdered)
      setInitialLoad(true)
    }

    fetchProducts()
  }, [getTokenSilently])

  useEffect(() => {
    storeStateToLocalStorage({ basket: { ...basket }, personalia: { ...personalia } })
  }, [basket, personalia])

  useEffect(() => {
    // TODO(saasen@23.07.2019): What do we do if this fails?
    const calculateMultiroom = async () => {
      const flattenedBasket = getBasketFlattened(basket)
      if (flattenedBasket.length > 0) {
        const token = await getTokenSilently()
        const response = await recalculateMultiroom(getBasketFlattened(basket), token)
        setMultiroom(response)
      }
    }

    calculateMultiroom()
  }, [basket, getTokenSilently, setMultiroom])

  if (!isLoaded)
    return <Loader fixed />

  return (
    <div className="app">
      <Switch>
        <Route path={`/(${SUBSCRIPTIONS_ROUTE}|${DETAILS_ROUTE}|${PERSONALIA_ROUTE}|${SUMMARY_ROUTE})/`}>
          <Header sessionExists={sessionExists} basket={basket} personalia={personalia} onClickingAbortSession={promptAbortFlowModal} />
        </Route>
        <Route>
          <Header user={user} logout={logout} />
        </Route>
      </Switch>

      {showSubscriptionChangeModal &&
        <Modal
          type='dialog'
          dialogTitle={`Bytte til ${getSubscription(productConfiguration, subscriptionChangeOfferId).name}`}
          dialogText="Dersom du bytter abonnement må steget, «Detaljer», gjøres på nytt."
          onConfirmingDialog={() => toggleSubscription(subscriptionChangeOfferId, history)}
          onAbortingDialog={closeSubscriptionChangeModal} />}

      {showOrderConfirmationModal &&
        <Modal
          type='confirmation'
          onDismissingConfirmation={closeOrderConfirmationModal} />}

      {showOrderErrorModal &&
        <Modal
          type='dialog'
          dialogTitle="Noe gikk galt!"
          dialogText="Vi får ikke registrert bestillingen for øyeblikket, prøv igjen om litt. Vil du starte en ny sesjon?"
          onConfirmingDialog={abortFlowAndResetState}
          onAbortingDialog={closeOrderErrorModal} />}

      {showAbortSessionModal &&
        <Modal
          type='dialog'
          dialogTitle="Du har en pågående sesjon"
          dialogText="Vil du fortsette denne eller starte på nytt?"
          dialogAbortButtonText='Fortsette'
          dialogConfirmButtonText='Start ny'
          onConfirmingDialog={startNewSessionConfirmation}
          onAbortingDialog={closeAbortSessionAndRedirectToSubscriptions} />}

      {showAbortFlowModal &&
        <Modal
          type='dialog'
          dialogTitle="Avslutt uten å lagre"
          dialogText="Kundeforholdet blir kun lagret dersom bestillingen fullføres, vil du avslutte?"
          onConfirmingDialog={abortFlowAndResetState}
          onAbortingDialog={closeAbortFlowModal} />}

      {showCreateUserModal &&
        <Modal
          type='user-creation'
          onConfirmingDialog={user => createUserRequest({ ...user, token })}
          onAbortingDialog={closeUserCreateModal}
          isSubmitting={isCreatingUser} />}

      {showDeleteUserModal &&
        <Modal
          type='dialog'
          dialogTitle="Slette bruker?"
          dialogText="Er du sikker på at du vil slette denne brukeren?"
          onConfirmingDialog={() => deleteUserRequest({ id: deletingUserId, token })}
          onAbortingDialog={closeUserDeleteModal}
          isSubmitting={isDeletingUser} />}

      <div className="router">
        <Switch>
          <Route path={relativeUrl(SUBSCRIPTIONS_ROUTE)}>
            {props =>
              <Subscriptions
                {...props}
                basket={basket}
                subscriptionFeatures={subscriptionFeatures}
                subscriptions={getSubscriptions(productConfiguration)} toggleSubscription={offerId => toggleSubscriptionChange(offerId, history)}
              />
            }
          </Route>
          <Route path={relativeUrl(DETAILS_ROUTE)}>
            {props =>
              <Details
                {...props}
                selectedSubscription={getSelectedSubscription(productConfiguration, basket)}
                premium={getPremium(basket, productConfiguration)} togglePremium={togglePremium}
                receivers={getReceivers(productConfiguration)} toggleReceiver={toggleReceiver}
                deliveryMethods={getDeliveryMethods(productConfiguration)} toggleDeliveryMethod={toggleDeliveryMethod}
                basket={basket}
                priceSummary={getPriceSummary(productConfiguration, basket, multiroom)}
              />
            }
          </Route>

          <Route path={relativeUrl(PERSONALIA_ROUTE)}>
            {props =>
              <Personalia
                {...props}
                initialValues={personalia}
                savePersonalia={savePersonalia}
                priceSummary={getPriceSummary(productConfiguration, basket, multiroom)}
              />
            }
          </Route>
          {/*
              TODO(saasen@10.07.2019): We should probably have at least a personalia selector, as if the fields
                changes in the form, everything else breaks. We should have one place to change this. That
                would be the selector's responsibility, to query the internal state consistently.
          */}
          {/*
              TODO(saasen@22.07.2019): We need to look at managing our state a little better
                as this gets out of hand very quickly as seen below...
          */}
          <Route path={relativeUrl(SUMMARY_ROUTE)}>
            {props =>
              <Summary
                {...props}
                basket={basket}
                subscriptions={getSubscriptions(productConfiguration)} toggleSubscription={toggleSubscription} selectedSubscription={getSelectedSubscription(productConfiguration, basket)}
                premium={getPremium(basket, productConfiguration)} togglePremium={togglePremium} selectedPremium={getSelectedPremium(productConfiguration, basket)}
                receivers={getReceivers(productConfiguration)} toggleReceiver={toggleReceiver} selectedReceivers={getSelectedReceivers(productConfiguration, basket)}
                deliveryMethods={getDeliveryMethods(productConfiguration)} toggleDeliveryMethod={toggleDeliveryMethod} selectedDeliveryMethod={getSelectedDeliveryMethod(productConfiguration, basket)}
                personalia={personalia} savePersonalia={savePersonalia}
                checkout={checkout} isSubmitting={isSubmitting} canCheckout={() => canCheckout(basket, personalia)}
                priceSummary={getPriceSummary(productConfiguration, basket, multiroom)}
              />
            }
          </Route>
          <Route path={relativeUrl(RESOURCES_ROUTE)}>
            <Resources partnerName={salesPartner.name} />
          </Route>
          <Route path="/" exact>
            <Home startNewSession={() => startNewSession(history)} />
          </Route>
          <Route path={relativeUrl(USERS_ROUTE)}>
            {props =>
              <Users
                {...props}
                partnerName={salesPartner.name}
              />
            }
          </Route>
          <Route>
            {_ =>
              <Page>
                <Page.Main>
                  <h1>404</h1>
                  <h2>Denne siden finnes ikke.</h2>
                  <Link to="/">Klikk her for å komme til forsiden.</Link>
                </Page.Main>
              </Page>
            }
          </Route>
        </Switch>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  showCreateUserModal: state.users.userCreateModalOpen,
  showDeleteUserModal: state.users.userDeleteModalOpen,
  deletingUserId: state.users.deletingUserId,
  isDeletingUser: state.users.isDeletingId !== undefined,
  isCreatingUser: state.users.isCreatingUser,
  salesPartner: state.app.salesPartner,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  closeUserCreateModal: usersCreators.closeUserCreateModal,
  closeUserDeleteModal: usersCreators.closeUserDeleteModal,
  createUserRequest: usersCreators.createUserRequest,
  deleteUserRequest: usersCreators.deleteUserRequest,
  getSalesPartnerRequest: appCreators.getSalesPartnerRequest,
}, dispatch)

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default withRouter(connectedComponent)
