import React from 'react'
import { Field } from 'react-final-form'
import classnames from 'classnames'

import './input-field.sass'

const hasError = (meta, input) => meta.error && (meta.touched || input.value)

const fieldClasses = (meta, input) => classnames('input-field', {
  'has-focused': (meta.dirty && meta.modified) || meta.active || input.value,
  'active': meta.active,
  'has-error': hasError(meta, input)
})

const InputField = ({ name, label, type, validate }) =>
  <Field name={name} label={label} type={type || 'text'} validate={validate}>
    {({ input, meta }) =>
      <div className={fieldClasses(meta, input)}>
        <div className="label-wrapper">
          <label htmlFor={name}>{label}</label>
        </div>
        <input {...input} id={name} autoComplete="off" />
        {hasError(meta, input) && <span className="error">{meta.error}</span>}
      </div>
    }
  </Field>

export default InputField
